<template>
  <div class="app-container">
    <eHeader
            @refresh-userlist="refreshUserlistHandle"
            :roles="roles"
            :sites="sites"
            :query="query"
            @query="queryHandle"
    />
    <!--表格渲染-->
    <div>
    <el-table v-loading="loading" :data="data" highlight-current-row stripe size="small" style="width: 100%;">
      <el-table-column label="站点名称">
        <template slot-scope="scope">
          <span>Maxain</span>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="用户名"/>
      <el-table-column label="头像">
        <template slot-scope="scope">
          <img :src="scope.row.avatar" class="el-avatar">
        </template>
      </el-table-column>
      <el-table-column prop="email" label="邮箱"/>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.enabled ? '激活':'锁定' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="注册日期">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="240px" align="center">
        <template slot-scope="scope">
          <edit
            v-if="checkPermission(['ADMIN','USER_ALL','USER_EDIT'])"
            :data="scope.row"
            :roles="roles"
            :sites="sites"
            :sup_this="sup_this"
          />
          <el-button
                  v-if="checkPermission(['ADMIN'])"
                  style="margin-right: 5px;"
                  type="primary"
                  @click="resetPassHandle(scope.row)"
                  size="mini"
          >重置密码</el-button>
          <el-popover
            v-if="checkPermission(['ADMIN','USER_ALL','USER_DELETE'])"
            :ref="scope.row.id"
            placement="top"
            width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
         <span>{{ name }}</span>
    </el-table>
    </div>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <!--分页组件-->
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { del,resetPass } from '@/api/system/user'
import { getRoleTree } from '@/api/system/role'
import { getSitesData } from "@/api/cms/srSite";
import { parseTime } from '@/utils/index'
import eHeader from '@/components/system/user/header'
import edit from '@/components/system/user/edit'
import store from '@/store'
import { mapGetters } from 'vuex'
export default {
  name:'user',
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      roles: [],
      sites: [],
      delLoading: false,
      sup_this: this,
      showButton: false
    }
  },
  created() {

    this.getRoles();
    this.getSites();
    this.$nextTick(() => {
      this.init();
      this.$store.dispatch('GetLoginUserPermission',this.name);
    });
  },
  computed: {
    ...mapGetters([
      'avatar',
      'name',
      'email',
      'createTime',
      'filesUploadApi'
    ])
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = 'crm/users'
      const sort = 'id,desc'
      const query = this.query
      const username = query.username
      const email = query.email
      const enabled = query.enabled
      this.params = { page: this.page, size: this.size, sort: sort }
      if (username !== '' && username !== null) { this.params['username'] = username }
      if (email !== '' && email !== null) { this.params['email'] = email }
      if (enabled !== '' && enabled !== null) { this.params['enabled'] = enabled }
      return true
    },
    subDelete(id) {
      this.delLoading = true
      del(id).then(res => {
        this.delLoading = false
        this.$refs[id].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    },
    getRoles() {
      getRoleTree().then(res => {
        this.roles = res
      })
    },
    getSites() {
      const params = {page:0,size:200}
      getSitesData(params).then(res => {
        this.sites = res.content;
      });
    },
    refreshUserlistHandle(){
      this.init();
    },
    queryHandle(){
      this.page = 0;
      this.init();
    },
    resetPassHandle(row) {
      this.$confirm('确定要重置密码', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
      }).then(() => {
        resetPass({
          userId: row.id,
        }).then((res) => {
          this.$notify({
            title: '重置成功',
            type: 'success',
            duration: 2500
          })
        });
      }).catch(() => {
        //几点取消的提示

      });

    }
  }
}
</script>

<style scoped>
</style>
